* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  font-family: Garamond, Century, 'Times New Roman', Times, serif;
}

.App {
  color: #0c4794;
}

/* Navbar  */
.custom-toggler.navbar-toggler {
    border: 2px solid rgb(12,71,148);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,204,109, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar {
  border-bottom: 1px solid #ffe8be;
  padding: 1rem 2rem;
}

.navbar-nav a{
  font-size: 20px;
  color: #0c4794;
}

/* Footer */
.footerContainer {
  display: flex;
  flex-direction: column;
}
.footer {
  border-top: 1px solid #facc6d;
  padding-top: 2rem;
  background-color: white;
}

.fa {
    font-family: Garamond, Century, 'Times New Roman', Times, serif;
    color: #0c4794;
    font-size: 115%;
}

/* Home Page Image Component */
.imageContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.image1,.image2,.image3 {
  border: 2px solid #facc6d;;
  background-position: cover;
  max-height: 390px;
  flex: 1;
  flex-shrink: 5;
}

.welcome::first-letter {
  font-size: 200%;
}

#quote {
  font-size: 100%;
  text-align: center;
  font-weight: bold;
  font-style: italic;

}

/* Across all pages */
h3 {
  font-weight: bold;
  font-size: 190%;
}

h5 {
  text-align: center;
  font-size: 120%;
  padding-top: 2%;
  font-style: italic;
  font-weight: bold;
}

h5::first-letter {
  font-size: 120%;
}

p {
  font-size: 115%;
}

ul {
  padding-left: 4%;
}

.flexPadd {
  padding-bottom: 10%;
}

@media only screen and (max-width: 768px){
  .image1,.image3 {
    display: none;
  }

  .image2{
    height: 300%;
    width: 100%;
  }

  .navbarCross {
    width: 50px;
    height: 55px;
    padding-left: 5px;
  }

  .navbarName {
    width: 170px;
    height: 55px;
  }
}

@media only screen and (max-width: 1024px){
  .image3 {
    display: none;
  }
}